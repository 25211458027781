import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Baufinanzierung = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Baufinanzierung – worauf muss ich achten?" showCalc={false} />
            <Article>
                <p>
                    Du träumst seit Langem davon, ein Haus zu bauen oder eine Wohnung zu kaufen, und bist nun endlich
                    kurz davor, diesen Traum zu verwirklichen – wären da nur nicht die Myriaden an
                    Finanzierungsmöglichkeiten, denen du dich schon bei der ersten Sondierung gegenüber siehst. Doch
                    keine Sorge: Wie du deine finanziellen Möglichkeiten im Blick behältst und die schier
                    unüberschaubare Zahl an Angeboten fundiert vergleichst, erklären wir dir im folgenden Beitrag.
                </p>
                <hr />

                <h2>Baufinanzierung mit Kredit</h2>
                <p>
                    Baufinanzierung mit Kredit Entscheidest du dich für den Kauf, so besteht der nächste Schritt darin
                    zu klären, ob du überhaupt damit rechnen kannst, einen Kredit zu erhalten. Um als Kreditnehmer*in
                    Frage zu kommen, solltest du einen Nachweis über ein regelmäßiges Einkommen während der vergangenen
                    zwölf Monate erbringen können. Bist du als Selbständige*r tätig, dann ist es ratsam, bereits zum
                    Nachweis von wenigstens einer Steuererklärung imstande zu sein.
                </p>
                <p>
                    Zudem ist zu klären, wie hoch der Maximalbetrag an Eigenmitteln ist, den du aufwenden kannst. Zur
                    Zeit ist es üblich, zumindest 20% der Finanzierungskosten mit Eigenkapital zu bestreiten – das
                    entspricht in etwa den Nebenkosten des Immobilienkaufs. Verfügst du jedoch über Eigenmittel in der
                    Höhe von mindestens 30% des Kaufpreises und hast dazu noch ein ausreichend hohes Einkommen, dann
                    solltest du bei der Beantragung eines Kredits auf keine allzu großen Hindernisse stoßen.
                </p>
                <p>
                    Wir raten dir zudem, von deinem jährlichen kostenlosen Auskunftsrecht beim{" "}
                    <a href="https://www.ksv.at/" target="_blank" rel="noreferrer noopener">
                        Kreditschutzverband
                    </a>{" "}
                    (KSV) Gebrauch zu machen. Dieser sammelt Informationen über das Zahlungsverhalten von Privatpersonen
                    und stellt Kreditgeber*innen auf diese Weise eine Datengrundlage zur Einschätzung deiner{" "}
                    <Link to="/artikel/bonitaet/" target="_blank" rel="noreferrer noopener">
                        Bonität
                    </Link>{" "}
                    bereit. Häufig kommt es leider dazu, dass ein Kredit aufgrund eines einzigen negativen Vermerks
                    verwehrt wird.
                </p>
                <hr />

                <h2>Wer hilft mir beim Vergleich von Kreditangeboten?</h2>
                <p>
                    Ganz einfach: miracl! Ausgehend von deiner Haushaltsrechnung und den entsprechenden
                    Einkommensnachweisen holen wir passende Angebote von unterschiedlichen Kreditinstituten ein und
                    helfen dir dabei, aus den unterschiedlichen Kreditarten, den für dich passenden zu finden.
                    Anschließenden unterstützen wir dich dabei, einen Vertrag abzuschließen, mit dem du rundum zufrieden
                    bist.
                </p>
                <hr />

                <h2>Welche Kosten muss ich beachten?</h2>
                <p>
                    Vor dem Vertragsschluss solltest du dir einen detaillierten Überblick über sämtliche Kosten
                    verschaffen, die zusätzlich zum Kaufpreis anfallen. Hierzu zählen u.a. die Grunderwerbsteuer, die
                    Makler*innenprovision, die Gebühr für die Grundbucheintragung und das Honorar für den*die Notar*in,
                    der*die den Kaufvertrag erstellt. Genaue Informationen über die Höhe der jeweiligen Kosten findest
                    du in unserem Artikel über Kaufnebenkosten beim Immobilien- bzw. Grundstückserwerb. In diesem
                    Zusammenhang ist es zudem ratsam, sich darüber zu informieren, welche Gebühren im Falle einer
                    vorzeitigen Rückzahlung zu begleichen sind – etwa dann, wenn du dich für eine Umschuldung
                    entscheidest.
                </p>
                <hr />

                <h2>Wie gestaltet sich die Rückzahlung des Kredits?</h2>
                <p>
                    Wesentlich ist in diesem Zusammenhang, dass Immobilienkredite in Österreich üblicherweise nach dem
                    Modell des Annuitätendarlehens abbezahlt werden. Das bedeutet, dass die monatlichen Raten zwar
                    gleich bleiben, sich das Verhältnis von{" "}
                    <Link to="/artikel/zinsen/" target="_blank" rel="noreferrer noopener">
                        Zinsen
                    </Link>{" "}
                    und tatsächlicher Rückzahlungsrate (der <strong>Tilgung</strong>) allerdings über die Laufzeit
                    hinweg verändert: Während der Anteil der Zinsen sinkt, steigt jener der Tilgung. Die Annuität kann
                    mit folgender Formel berechnet werden:
                </p>
                <ul>
                    <li>Annuität = Zinsanteil + Tilgungsanteil </li>
                </ul>
                <p>
                    Zuletzt solltest du noch die Möglichkeit sogenannter <strong>Sondertilgungen</strong>
                    beachten – d.h. zusätzlicher Teilrückzahlungen, die du mit deiner Bank vereinbarst und die dir dabei
                    helfen können, die Tilgungsrate deines Kredits zu beeinflussen. Höchstens 10 000 Euro kannst du in
                    Österreich gebührenfrei in Form einer Sondertilgung zurückzahlen.
                </p>
                <hr />

                <h2>Du willst es genauer wissen?</h2>
                <p>
                    Dann hast du hier und jetzt zwei Möglichkeiten: Entweder du siehst dir unseren{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kreditrechner
                    </Link>{" "}
                    an. Damit kannst du in wenigen Klicks ein gutes Gefühl Kreditrate, Zinsen und Zinsmodelle bekommen.
                    Oder du vereinbarst direkt einen Termin mit einem unserer Berater:innen.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Zum Online-Termin
                    </a>
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"baufinanzierung"}></BreadcrumbList>
            <ArticleStructuredData page={"baufinanzierung"} heading={"Baufinanzierung – worauf muss ich achten?"} />
        </Layout>
    );
};

export default Baufinanzierung;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.baufinanzierung", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
